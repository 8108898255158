<messages>["./Host"]</messages>

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          {{ $t ('create.success') }}
          <template #link>
            <router-link
              :to="{name: 'host.view', params: { registry, handle }}"
              v-text="handle"/>
          </template>
        </v-alert>
      </v-col>
    </base-layout>

    <v-alert
      id="invalidDataAlert"
      v-t="'general.invalid.form'"
      type="error"
      :value="isInvalidDataAlertVisible"/>

    <host-create-update
      :is-create="true"
      @success="onSuccess"
      @failure="onFailure"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"/>
  </div>
</template>

<script>
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import HostCreateUpdate from './components/HostCreateUpdate'
  import {mapMutations} from 'vuex'

  export default {
    name: 'HostCreate',

    components: {
      BaseLayout,
      HostCreateUpdate
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        handle: null,
        registry: null,
        isInvalidDataAlertVisible: false
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),

      onSuccess ({handle, registry}) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('create.success', {link: handle}),
          objects: [{name: handle, link: {name: 'host.view', params: {registry, handle}}}]
        })
        this.handle = handle
        this.registry = registry
        this.isSuccessAlertVisible = true
      },

      onFailure () {
        this.isSuccessAlertVisible = false
        this.scrollToAlert ()
      },

      onDataValidityChanged (isValid) {
        console.warn ('onDataValidityChanged', isValid)
        this.isInvalidDataAlertVisible = !isValid
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      back () {
        this.$router.back ()
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>
